// import Bugsnag from '@bugsnag/js';
// import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';
import React, { Fragment } from 'react';
import { Toaster } from 'sonner';
import { APIProvider } from '@vis.gl/react-google-maps';

import { ApolloProvider } from '@apollo/client';
import { GlobalStyle } from './src/components/Shared/Shared';
import client from './src/utils/client';
import config from './src/utils/config';
if (typeof window !== 'undefined') {
  import('firebase/auth');
  import('firebase/app');
  import('firebase/firestore');
  import('firebase/messaging');
  import('firebase/storage');
  import('firebase/analytics');
  import('firebase/remote-config');
}

// const isProd = config.ENV === 'production';
// if (isProd) {
//   Bugsnag.start({
//     apiKey: config.BUGSNAG_KEY,
//     plugins: [new BugsnagPluginReact()]
//   });
// }
// const BugsnagErrorBoundary = isProd ? Bugsnag.getPlugin('react')!.createErrorBoundary(React) : Fragment;

export const wrapRootElement = ({ element }) => {
  return (
    <RollbarProvider config={{ accessToken: config.ROLLBAR_KEY, environment: config.ROLLBAR_ENV }}>
      <RollbarErrorBoundary>
        {/* <BugsnagErrorBoundary> */}
        <ApolloProvider client={client}>
          <GlobalStyle />
          <Toaster />
          <APIProvider apiKey={config.GOOGLE_MAPS_API_KEY}>{element}</APIProvider>
        </ApolloProvider>
        {/* </BugsnagErrorBoundary> */}
      </RollbarErrorBoundary>
    </RollbarProvider>
  );
};
