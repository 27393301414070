import { graphql, useStaticQuery } from 'gatsby';

export default function useIcons() {
  const data = useStaticQuery(graphql`
    query {
      plus: file(relativePath: { eq: "plus.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 60, height: 60)
        }
      }
      plusSmall: file(relativePath: { eq: "plus.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 40, height: 40)
        }
      }
      plusSmaller: file(relativePath: { eq: "plus.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 25, height: 25)
        }
      }
      plusTransparentSmall: file(relativePath: { eq: "plus.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 25, height: 25)
        }
      }

      plusBlack: file(relativePath: { eq: "plusBlack.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 25, height: 25)
        }
      }
      plusBlackSVG: file(relativePath: { eq: "plusBlack.svg" }) {
        publicURL
        extension
      }
      customViewSVG: file(relativePath: { eq: "customView.svg" }) {
        publicURL
        extension
      }
      notificationDot: file(relativePath: { eq: "notificationDot.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 7, height: 7)
        }
      }
      camera: file(relativePath: { eq: "camera.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 60, height: 60)
        }
      }
      user: file(relativePath: { eq: "person.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 40, height: 40)
        }
      }

      userInDrawers: file(relativePath: { eq: "person.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 32, height: 32)
        }
      }

      chat: file(relativePath: { eq: "chat-icon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 60, height: 60)
        }
      }
      chatSVG: file(relativePath: { eq: "chat.svg" }) {
        publicURL
        extension
      }
      bookings: file(relativePath: { eq: "bookings.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30, height: 24)
        }
      }
      bookingsSVG: file(relativePath: { eq: "bookings.svg" }) {
        publicURL
        extension
      }
      membership: file(relativePath: { eq: "membership.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30, height: 24)
        }
      }
      membershipSVG: file(relativePath: { eq: "membership.svg" }) {
        publicURL
        extension
      }
      pets: file(relativePath: { eq: "pets.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30, height: 24)
        }
      }
      petsSVG: file(relativePath: { eq: "pets.svg" }) {
        publicURL
        extension
      }
      chatSideBar: file(relativePath: { eq: "chat.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30, height: 24)
        }
      }
      routes: file(relativePath: { eq: "routes.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30, height: 30)
        }
      }
      routesSVG: file(relativePath: { eq: "routes.svg" }) {
        publicURL
        extension
      }

      services: file(relativePath: { eq: "store.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30, height: 24)
        }
      }
      servicesSVG: file(relativePath: { eq: "store.svg" }) {
        publicURL
        extension
      }
      orders: file(relativePath: { eq: "orders.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30, height: 24)
        }
      }
      logo: file(relativePath: { eq: "Logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 56)
        }
      }
      logoLarge: file(relativePath: { eq: "Logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 70)
        }
      }
      add: file(relativePath: { eq: "add.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      pin: file(relativePath: { eq: "pin.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      more: file(relativePath: { eq: "more.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30, height: 30)
        }
      }
      moreSVG: file(relativePath: { eq: "more.svg" }) {
        publicURL
        extension
      }

      moreMid: file(relativePath: { eq: "more.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 24, height: 24)
        }
      }

      moreLarge: file(relativePath: { eq: "more.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 29, height: 28)
        }
      }
      moreIcon: file(relativePath: { eq: "moreIcon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 25, height: 25)
        }
      }
      moreIconSVG: file(relativePath: { eq: "moreIcon.svg" }) {
        publicURL
        extension
      }
      moreBlack: file(relativePath: { eq: "moreBlack.svg" }) {
        publicURL
        extension
      }

      moreTabs: file(relativePath: { eq: "moreTabs.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }

      less: file(relativePath: { eq: "less.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30, height: 30)
        }
      }
      lessSVG: file(relativePath: { eq: "less.svg" }) {
        publicURL
        extension
      }

      lessMid: file(relativePath: { eq: "less.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 21, height: 21)
        }
      }

      lessLarge: file(relativePath: { eq: "less.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 29, height: 28)
        }
      }
      addPhoto: file(relativePath: { eq: "addPhoto.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      addPhotoLarge: file(relativePath: { eq: "addPhoto.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 40)
        }
      }
      closeModal: file(relativePath: { eq: "close.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      closeModalMid: file(relativePath: { eq: "close.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 16, height: 16)
        }
      }
      closeTransparent: file(relativePath: { eq: "closeTransparent.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 14)
        }
      }
      closeTransparentMid: file(relativePath: { eq: "closeTransparent.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 16, height: 16)
        }
      }
      closeTransparentBlackMid: file(relativePath: { eq: "closeTransparent.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 16, height: 16)
        }
      }
      closeTransparentBlack: file(relativePath: { eq: "closeTransparentBlack.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 14)
        }
      }
      closeTransparentBlackSVG: file(relativePath: { eq: "closeTransparentBlack.svg" }) {
        publicURL
        extension
      }
      closeTrasparentWide: file(relativePath: { eq: "closeTrasparentWide.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 12)
        }
      }
      edit: file(relativePath: { eq: "editIcon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      link: file(relativePath: { eq: "link.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      delete: file(relativePath: { eq: "delete_icon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      deleteOutlined: file(relativePath: { eq: "deleteOutlined.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 24)
        }
      }
      chevronRight: file(relativePath: { eq: "chevronRight.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      chevronLeft: file(relativePath: { eq: "chevronLeft.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      refresh: file(relativePath: { eq: "refresh.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      calendar: file(relativePath: { eq: "calendarIcon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      calendarSVG: file(relativePath: { eq: "calendar.svg" }) {
        publicURL
        extension
      }
      dateSelecorSVG: file(relativePath: { eq: "dateSelector.svg" }) {
        publicURL
        extension
      }
      copy: file(relativePath: { eq: "copy_icon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      notes: file(relativePath: { eq: "notes_icon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      bpm: file(relativePath: { eq: "hr.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 23)
        }
      }
      temp: file(relativePath: { eq: "temp.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 23)
        }
      }
      weight: file(relativePath: { eq: "weight.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 23)
        }
      }
      bcs: file(relativePath: { eq: "bcs.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 23)
        }
      }
      pet1: file(relativePath: { eq: "pet_1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 512, height: 512)
        }
      }
      pet2: file(relativePath: { eq: "pet_2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 512, height: 512)
        }
      }
      pet3: file(relativePath: { eq: "pet_3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 512, height: 512)
        }
      }
      pet4: file(relativePath: { eq: "pet_4.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 512, height: 512)
        }
      }
      pet5: file(relativePath: { eq: "pet_5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 512, height: 512)
        }
      }
      filter: file(relativePath: { eq: "filter.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 25, height: 25)
        }
      }
      filterSVG: file(relativePath: { eq: "filter.svg" }) {
        publicURL
        extension
      }

      multiSelectBlack: file(relativePath: { eq: "multiSelectBlackIcon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 25, height: 25)
        }
      }

      multiSelectBlackSVG: file(relativePath: { eq: "multiSelectBlack.svg" }) {
        publicURL
        extension
      }

      moreMenu: file(relativePath: { eq: "moreMenu.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }

      smallX: file(relativePath: { eq: "smallX.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 20)
        }
      }
      expand: file(relativePath: { eq: "expand.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 20)
        }
      }
      attach: file(relativePath: { eq: "attach.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 20)
        }
      }
      calendarIcon: file(relativePath: { eq: "calendarIcon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30, height: 24)
        }
      }
      search: file(relativePath: { eq: "search.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      searchIcon: file(relativePath: { eq: "searchIcon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 13, height: 13)
        }
      }
      searchBlack: file(relativePath: { eq: "searchBlack.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      searchBlackSVG: file(relativePath: { eq: "searchBlack.svg" }) {
        publicURL
        extension
      }
      topArrow: file(relativePath: { eq: "top_arrow.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 18)
        }
      }
      checkmarkEmpty: file(relativePath: { eq: "checkmarkEmpty.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }

      checkmarkBlack: file(relativePath: { eq: "checkmarkBlack.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 18)
        }
      }
      closeEmpty: file(relativePath: { eq: "closeEmpty.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      groupBookings: file(relativePath: { eq: "groupBookings.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 15, height: 15)
        }
      }
      creditCard: file(relativePath: { eq: "creditCard.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      bulkConfirm: file(relativePath: { eq: "bulkConfirm.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      bulkReject: file(relativePath: { eq: "bulkReject.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      toggleCheckbox: file(relativePath: { eq: "toggleCheckbox.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      meal: file(relativePath: { eq: "meal.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      sleep: file(relativePath: { eq: "sleep.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      poo: file(relativePath: { eq: "poo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      pdfPlaceholder: file(relativePath: { eq: "pdf-placeholder.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 400)
        }
      }
      chevronDown: file(relativePath: { eq: "chevronDown.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 22)
        }
      }
      noteDelete: file(relativePath: { eq: "noteDelete.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 14)
        }
      }
      chart: file(relativePath: { eq: "chart.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      editChart: file(relativePath: { eq: "editChart.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      tableChart: file(relativePath: { eq: "tableChart.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      expandChart: file(relativePath: { eq: "expandChart.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      viewSwitchOn: file(relativePath: { eq: "viewSwitchOn.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 50, height: 31)
        }
      }
      viewSwitchOff: file(relativePath: { eq: "viewSwitchOff.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 50, height: 31)
        }
      }
      groupFilters: file(relativePath: { eq: "groupFilters.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30)
        }
      }
      export: file(relativePath: { eq: "export.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 20)
        }
      }
      exportSVG: file(relativePath: { eq: "export.svg" }) {
        publicURL
        extension
      }
    }
  `);
  return data;
}
